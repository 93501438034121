const canvasWidth = 500;
const canvasHeight = 700;
let gameOver = false;
const frameLength = 20;

const startGame = () => {
  gameState.start();

  player = new Player(30, 30, 40);
  block = new Block(-gameSpeed);

  interval = setInterval(gameLoop, frameLength);
}

const canvas = document.createElement("canvas");

const gameState = {
  start: () => {
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    document.getElementById("container").append(canvas);
  },
  stop: () => {
    canvas.remove();

    const gameOverMessage = document.createElement("h1");
    gameOverMessage.innerText = "Game over :(";

    const restartButton = document.createElement("button");
    restartButton.innerText = "Restart game";
    restartButton.addEventListener("click", () => {
      gameState.restart();
    })

    document.getElementById("container").append(gameOverMessage);
    document.getElementById("container").append(restartButton);

    gameState._gameOverMessage = gameOverMessage;
    gameState._restartButton = restartButton;

    gameOver = true;
    clearInterval(interval)
  },
  restart: () => {
    activeBlocks = [];
    player = undefined;
    gameSpeed = 3;
    score = 0;
    document.getElementById("score").innerText = score;

    gameOver = false;

    gameState._gameOverMessage.remove();
    gameState._restartButton.remove();

    startGame();
  }
}

let activeBlocks = [];
let player;
let gameSpeed = 3;
let score = 0;
let interval;

const gameLoop = () => {
  if (gameOver) return;

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  player.applyForces();
  player.draw();

  for (let i in activeBlocks) {
    const activeBlock = activeBlocks[i];

    activeBlock.updatePosition();
    activeBlock.draw();

    if (activeBlock.x + activeBlock.width < 0) {
      score++;
      document.getElementById("score").innerText = score;
      activeBlocks.splice(i);
      
      gameSpeed += 0.2;
      new Block(-gameSpeed);
    }

    const isAtCollisionHeight = player.y < activeBlock.upperRecHeight || (player.y + player.height > activeBlock.upperRecHeight + activeBlock.gapSize);

    if (activeBlock.x <= player.x && activeBlock.x + activeBlock.width >= player.x && isAtCollisionHeight) {
      gameState.stop();
    }
  }
  
  if (player.y > canvasHeight || player.y < 0) {
    
    gameState.stop();
  }
}

document.addEventListener("keydown", (ev) => {
  if (ev.code === "Space" || ev.code === "ArrowUp" || ev.code === "KeyW") {
    player.jump();
    
  }
});

canvas.addEventListener("click", () => {
  player.jump();
})

document.body.onload = () => {
  startGame();
}; 

function Player(width, height, x)  {
  this.width = width;
  this.height = height;
  this.x = x;
  this.y = canvasHeight/2;

  this.velocity = -0.15; // pxs/ms

  this.draw = () => {
    ctx = canvas.getContext("2d");
    ctx.fillStyle = "rgb(255, 102, 0)";
    
    ctx.fillRect(this.x, this.y, this.width, this.height);

    ctx.restore();
  }
  this.applyForces = () => {
    const dt = frameLength;
    const g = Math.pow(5.5, -4); // px/ms
  
    const a = g;
    const v = this.velocity + a * dt;
    const y = this.y + v * dt;

    this.velocity = v;
    this.y = y;
  }
  this.jump = () => {
    player.velocity = -0.5;
  }
}

function Block(velocity) {
  this.width = 70;
  this.velocity = velocity;
  this.x = canvasWidth;

  this.gapSize = 150;

  this.upperRecHeight = Math.random() * (canvasHeight*0.7) + 50;
  this.lowerRecHeight = canvasHeight - this.gapSize - this.upperRecHeight;

  activeBlocks.push(this);

  this.draw = () => {
    ctx = canvas.getContext("2d");
    ctx.fillStyle = "green";

    // Upper rec
    ctx.fillRect(this.x, 0, this.width, this.upperRecHeight);
    // Lower rec
    ctx.fillRect(this.x, canvasHeight - this.lowerRecHeight, this.width, this.lowerRecHeight)
  }
  this.updatePosition = () => {
    this.x = this.x + this.velocity;
  }
}